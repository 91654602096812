import { Component, OnInit, NgZone } from '@angular/core';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../dialog/dialog.component';
import { FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  isValid = false;
  regDataId = 'unknown';
  email = new FormControl('', [Validators.required, Validators.email]);


  constructor(
    private user: UserService,
    public router: Router,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private ngZone: NgZone
  ) {}

  ngOnInit() {}

  async fbLogin() {
    const result = await this.user.fbLogin(this.regDataId);

    if (result > 0) {
      console.log(result);
      this.router.navigate(['/Guidelines']);
    } else {
      console.log(result);
      this.openDialog(
        -1,
        'Error: ' + String(result),
        // tslint:disable-next-line: max-line-length
        'An unexpected error has occurred, Please try again later'
      );
    }
    // this.user.fbLogin().then((result) => {
    //   console.log('User has been logged in');
    //   this.router.navigate(['/Guidelines']);
    // }).catch((result) => {
    //   console.log(result);
    // });
  }

  async checkEmail() {
    let dialogref;
    this.ngZone.run(() => {
      dialogref = this.openDialog(
        4,
        'Please Wait!',
        'Checking Email'
      );
    });
    const emailId = this.email.value;
    const res = await this.user.checkEmail(emailId);
    if (res) {
      dialogref.close();
      this.dialog.closeAll();
      this._snackBar.open('Varified! Continue with Facebook', 'Ok', {
        duration: 1500
      });
      this.isValid = true;
      this.regDataId = res;
      console.log(this.regDataId);
    } else {
      dialogref.close();
      this.dialog.closeAll();
      this._snackBar.open('Error: Unknown email address', 'Close', {
        duration: 2000
      });
    }
  }

  openDialog(type: number, title: string, content: string) {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '500px',
      data: { type, title, content }
    });
    return dialogRef;
  }
  getErrorMessage() {
     //return this.email.hasError('required') ? 'You must enter a value' :
         return this.email.hasError('email') ? 'Not a valid email' :
            '';
  }
}
