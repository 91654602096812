import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { DataService } from '../../services/data.service';
import { UserService } from 'src/app/services/user.service';
import { DialogComponent } from '../dialog/dialog.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  showLoading = true;
  userID: string;
  user_name: string;
  count: number;
  show_leaderboard = false;
  rank: number;
  refcode: string;
  total_points: number;
  posts_shared: number;
  task_completed: number;
  tickets_sold: number;
  leaderboard: any;
  discountCodes = [];

  constructor(
    public dialog: MatDialog,
    private dataservice: DataService,
    private userservice: UserService,
    private _snackBar: MatSnackBar
  ) {}

  async ngOnInit() {
    this.userID = this.userservice.getUserId();
    const result = await this.dataservice.getDashboardData();
    this.hideLoader();
    if (result) {
      this.count = result.count;
      this.user_name = result.userName;
      this.rank = result.rank;
      this.total_points = result.totalPoints;
      this.refcode = result.refCode ? result.refCode : 'ReferalCode';
      this.tickets_sold = result.ticketsBooked;
      this.posts_shared = result.postsShared;
      this.task_completed = result.tasksCompleted;
      this.leaderboard = result.leaderBoard;
      this.discountCodes = result.coupons;
    }
  }

  hideLoader() {
    this.showLoading = false;
    return;
  }
  copyMessage(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this._snackBar.open('Copied to clipboard', 'Close', {
      duration: 1500
    });
  }

  async openRedeemDialog(status: string, message: string, showRedeem: boolean) {
    if (this.tickets_sold < 5) {
      // tslint:disable-next-line: max-line-length
      this.openDialog(
        0,
        'Redeem discount codes',
        `Sorry, you cannot redeem discount codes yet. Sell ${5 -
          this
            .tickets_sold} more tickets with your referral code to earn a 100% discount on Esummit Merchandise.`
      );
    } else if (
      this.tickets_sold < 10 &&
      this.tickets_sold >= 5 &&
      this.discountCodes.length == 1
    ) {
      // tslint:disable-next-line: max-line-length
      this.openDialog(
        0,
        'Redeem discount codes',
        `Sorry, you cannot redeem your next discount code yet. Sell ${10 -
          this
            .tickets_sold} more tickets with your referral code to earn a discount code of 100% off on Esummit ticket and Merchandise.`
      );
    } else if (this.tickets_sold >= 10 && this.discountCodes.length == 2) {
      // tslint:disable-next-line: max-line-length
      this.openDialog(
        0,
        'Redeem discount codes',
        'Sorry, you cannot redeem your next discount code. Max redeem of discount codes reached.'
      );
    } else {
      const dref = this.openDialog(
        3,
        'Redeem discount codes',
        'By clicking Redeem Code you will get a discount code which you can use on ecelliitk.org/esummit'
      );
      dref.afterClosed().subscribe(res => {
        if (res) {
          this.genCoupenCode();
        }
      });
    }
  }

  async genCoupenCode() {
    try {
      const httpres = await this.dataservice.getRedeemData();
      this.discountCodes.push({
        coupon_code: httpres.coupon_code,
        coupon_value: httpres.coupon_value
      });
      this._snackBar.open('Coupon code generated!', 'Close', {duration: 1500});
    } catch (error) {
      console.log(error);
      this._snackBar.open('Error Occurred try again letter', 'Close', {duration: 2000});
      return;
    }
  }

  openRedeemInfoPopup() {
    this.openDialog(
      2,
      'Referral code',
      // tslint:disable-next-line: max-line-length
      'Earn points and discount codes by telling your friends about E-Summit!. Register for Esummit at ecelliitk.org/esummit . Tell them to use your referral code while booking tickets. Anybody who will use this referral code while buying ticket will get a discount of flat Rs 10% off. And You will get 100 points for selling every ticket. if you sold 5 tickets then you will get a flat 50% off discount code. if you sold 5 more tickets (total 10 tickets) then you will get a flat 100% off discount code.'
    );
  }

  openDialog(type: number, title: string, content: string) {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '500px',
      data: { type, title, content }
    });
    return dialogRef;
  }
}
