// Production
const FB_APPID = "452524931986646";
const SERVER_DOMAIN = "https://ca-back.onrender.com/";

// Local
// const FB_APPID = '425044981657116';
// const SERVER_DOMAIN = 'http://localhost:5200/';

const FB_API_VERSION = "v3.3";
const SERVER_AUTH_URI = "auth/";
const SERVER_OAUTH_URI = "Oauth/";
const SERVER_CHECKEMAIL_URI = "checkEmail/";
const SERVER_DASHBOARD_URI = "data/datasum/";
const SERVER_DATACOUNT_URI = "data/datacount/";
const SERVER_POSTS_URI = "data/posts/";
const SERVER_TASKS_URI = "data/tasks/";
const SERVER_POSTSHARE_URI = "data/postgain/";
const SERVER_REDEEM_URI = "data/redeem";

// Session update interval (in seconds)
const SESSION_UPDATE_INTERVAL = 1800;

export {
  FB_APPID,
  FB_API_VERSION,
  SERVER_DOMAIN,
  SERVER_AUTH_URI,
  SERVER_OAUTH_URI,
  SERVER_DASHBOARD_URI,
  SERVER_CHECKEMAIL_URI,
  SERVER_DATACOUNT_URI,
  SERVER_POSTS_URI,
  SERVER_TASKS_URI,
  SERVER_POSTSHARE_URI,
  SESSION_UPDATE_INTERVAL,
  SERVER_REDEEM_URI,
};
